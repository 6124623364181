<template>
  <div id="data-list-list-view">
                <feather-icon icon="ArrowLeftIcon" svgClasses="h-8" style="cursor:pointer" @click="$router.push('/Patient')"/>
 <patient-Tab
                  class="mt-4"
                  :mainTabName="'PendingPayments'"
                />
 <div>
      <vx-card class="flex-1" v-bind:title="$t('Pending Payment')">
       
      <vs-table
          ref="table"
          pagination
          :max-items="5"
          :data="Model"
        >
          <template slot="thead">
             <vs-th>
             {{ $t("Appointment") }}/
          {{ $t("Surgeries") }} </vs-th>
             <vs-th> {{ $t("Date") }} </vs-th>
             <vs-th> {{ $t("Time") }} </vs-th>
             <vs-th>{{ $t("Requested From") }} </vs-th>
          
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
                <vs-td>
                   <div v-if="item.PatientReservedSessionID>0">
                      {{ $t("Online Appointment") }}
                  </div>
                  <div v-if="item.PatientReservedSurgeryID>0">
                       {{ item.SurgeryName }}
                  </div>
                </vs-td>
                 <vs-td>
                  <p v-if="item.PatientReservedSessionID>0">{{ item.DateFormatted | formatDate() }}
                 
                  </p>
                    <p v-else>{{ item.DateFromTo }}
                  </p>
                    </vs-td>

                  <vs-td>
                  <p v-if="item.PatientReservedSessionID>0">
                     form : {{ item.TimeFrom}} , To : {{ item.TimeTo}}  
                  </p>
                    </vs-td>
                    <vs-td  >
                      <div >
                        <imageLazy
                    v-show="item.HospitalName"
                      :imageStyle="{ 'border-radius': '40px','width':'80px','height':'80px',size:'100px' }"
                      :imageClass="'doctorImage'"
                      :src="baseURL + item.HospitalImage"
                      placeHolderType="hospital"
                    />
                        <imageLazy
                        v-show="item.PatientReservedSessionID>0"
                      :imageStyle="{ 'border-radius': '40px','width':'80px','height':'80px',size:'100px' }"
                      :imageClass="'doctorImage'"
                      :src="baseURL + item.DoctorImage"
                      placeHolderType="doctor"
                    />
                    
                        </div>
                        <p  v-if="item.PatientReservedSessionID>0">{{$t("Dr/")}}{{ item.DoctorName }}</p>
                        <p  v-if="item.HospitalName">{{$t("Hospital/ ")}}{{ item.HospitalName }}</p>
                    </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
         </div>
         <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
           
            <vs-button type="border" color="danger" :to="{ name: 'Patient' }">{{
              $t("back")
            }}</vs-button>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import { domain } from "@/gloabelConstant.js";
import patientTab from "@/views/tabs/patientTab.vue";
import imageLazy from "@/components/image_lazy.vue";
export default {
  data() {
    return {
      baseURL: domain,
      Model: [],
      patientModel:{}
    };
  },
  components:{
    patientTab,
    imageLazy
  },
  mounted() {
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
  const ID = this.$route.params.ID;
    if (ID != undefined) {
       this.$store.dispatch("patientList/GetPatient", ID).then(res => {
        this.patientModel = res.data.Data;
       this.$store.commit("SET_SetTitle",''+ this.patientModel.NameEN);
      });
    }
    this.$store
      .dispatch(
        "patientList/getPatientPendingList",
        this.$route.params.ID
      )
      .then(res => {
        if (res.status == 200) {
          this.Model = res.data.Data;
        }
      })
      .catch(() => {
        window.showError();
      });
  }
};
</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>